@import './_slide';

$gallerySlideTransform: rotateY(calc(var(--slide-offset) * var(--coverflow-rotation) * 1deg)) scale(calc(1 - (var(--slide-offset-abs) * var(--coverflow-scaleFactor))));

@mixin gallery-slide-shadow($orientation) {
    // from: black, opacity = -x^2 * 0.000099 + x * 0.018 (0 => 0, 90 => 0.8, 180 => 0)
    // to: transparent
    background: linear-gradient($orientation,
    rgba(0, 0, 0, calc(var(--slide-offset-abs) * var(--coverflow-rotation) * var(--slide-offset-abs) * var(--coverflow-rotation) * 0.000099 + var(--slide-offset-abs) * var(--coverflow-rotation) * 0.018)),
    calc(1% + var(--rotation-relative) / 90 * 50%), #00000000) {} }

.gallery-coverflow {
    .gallery-slide {
        @extend %base-gallery-slide;
        transform-origin: 50% 50%;
        @include transform(translateX(calc(var(--slide-index) * (var(--slide-width) + var(--slide-gap)) * 1px)) translateX(-50%) $gallerySlideTransform); }

    .gallery-wrapper {
        @include transform(translateX(calc(var(--active-slide) * (var(--slide-width) + var(--slide-gap)) * -1px + var(--base-slide-offset)))); }

    .gallery-slide-shadow {
        @extend %fill-parent;
        border-radius: var(--coverflow-shadow-border-radius);
        pointer-events: none;
        @include gallery-slide-shadow(calc(90deg * ((var(--slide-offset)) / var(--slide-offset-abs)))); } }
