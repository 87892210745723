@import './_mixins';
@import './_utils';

%base-gallery-slide {
    position: absolute; }

.gallery-slide {
    @extend %base-gallery-slide; }

.gallery-slide-active {}
