html,
body,
#root {
  height: 100%;
  font-family: "Roboto", sans-serif;
}

body {
  overflow: hidden;
  background: var(--main-bg-color);
}

a:link {
  text-decoration: none;
  color: inherit;
}

a:active {
  text-decoration: none;
  color: inherit;
}

a:visited {
  text-decoration: none;
  color: inherit;
}

.vertical-align {
  display: grid;
  align-items: center;
}

.horizontal-align {
  display: grid;
  justify-items: center;
}

:root {
  --main-bg-color: #333;
  --main-bg-color-darker: #222222;

  --landing-links-color: #4caf50;
}

.posRelative {
  position: relative;
}

.typographyBody3 {
  font-size: 0.75rem !important;
}

.gallery .gallery-control {
  stroke: black;
  stroke-width: 1px;
  stroke-linejoin: round;
}