%fill-parent {
    width: 100%;
    height: 100%;
    position: absolute; }

%above-all {
    z-index: 1000; }

.fill-parent {
    @extend %fill-parent; }

.above-all {
    @extend %above-all; }
