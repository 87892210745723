@import './_mixins';

// effects
@import './_coverflow';
@import './_utils';

.grab-cursor {
    cursor: grab; }

.gallery-container {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden; }

.gallery-wrapper {
    width: 100%;
    height: 100%;
    perspective: 800px;
    perspective-origin: center center;
    position: relative;
    overflow: visible; }

.gallery-mouse-blocker {
    @extend  %fill-parent;
    @extend %above-all; }
